
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../form"

const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="apijwtuser"

// TODO Additional security/checks for user and usertype updating

const usertypepickerfields = [
	{"label":"Name", "dbfield": "usertype_name", "type": "text", "filtertype": "textbox"},
];

const usertypepickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const apijwtuserproviderpickerfields = [
	{"label":"Name", "dbfield": "apijwtuserprovider_name", "type": "text", "filtertype": "textbox"},
];

const apijwtuserproviderpickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const searchFields = [
	{"label":"Service", "dbfield": "apijwtuserprovider_name", "type": "text", "filtertype": "text"},
	{"label":"Name", "dbfield": "apijwtuser_name", "type": "text", "filtertype": "text"},
	{"label":"email", "dbfield": "apijwtuser_identifier", "type": "text", "filtertype": "text"},

	{"label":"Status", "dbfield": "apijwtuser_providerusername", "type": "custom", "filtertype": "none"},

	{"label":"Access", "dbfield": "usertype_name", "type": "text", "filtertype": "text"},
];

const newFormFields = [
	[
		{"label":"Service", "field": "apijwtuserprovider_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": apijwtuserproviderpickerfields, "pickerfilters": apijwtuserproviderpickerfilter},
		{"label":"Name", "field": "apijwtuser_name", "value": "", "input": "text", "mode": "required"},
		{"label":"email", "field": "apijwtuser_identifier", "value": "", "input": "email", "mode": "unique"},
		{"label":"Access", "field": "usertype_name", "value": "", "input": "picker", "mode": "required", "pickerfields": usertypepickerfields, "pickerfilters": usertypepickerfilter},
	]
];

const formFields = [
	[
		//apijwtuser_providerusername
		{"label":"Service", "field": "apijwtuserprovider_name", "value": "", "input": "picker", "mode": "readonly", "pickerfields": apijwtuserproviderpickerfields, "pickerfilters": apijwtuserproviderpickerfilter},
		{"label":"Name", "field": "apijwtuser_name", "value": "", "input": "text", "mode": "required"},
		{"label":"email", "field": "apijwtuser_identifier", "value": "", "input": "email", "mode": "readonly"},
		{"label":"Status-internal", "field": "apijwtuser_providerusername", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Active", "field": "apijwtuser_code", "value": "", "input": "checkbox", "mode": "normal"},
		{"label":"Access", "field": "usertype_name", "value": "", "input": "picker", "mode": "required", "pickerfields": usertypepickerfields, "pickerfilters": usertypepickerfilter},
	]
];

const ApijwtuserForm = ({location, allowadd, allowdelete, customnewformfields, customeditformfields}) => {
	const [token, setToken] = useState("");
	const [userfullname, setUserfullname] = useState("");
	const [pagetitle, setPagetitle] = useState("");


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		/*
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		*/
		/*
		// Already validated by input type
		if (params.hasOwnProperty("apijwtuser_identifier")) {
			if (formatTools.validateEmail(params.apijwtuser_identifier) === false) {
				callback({"status":"Error", "message":"Invalid email address "+params.apijwtuser_identifier});
				return;
			}
		}
		*/
		if (params.hasOwnProperty("apijwtuser_code")) {
			if (parseInt(params.apijwtuser_code, 10) === 0) {
				params.apijwtuser_providerusername = "disabled";
			} else {
				// Re-activated
				params.apijwtuser_providerusername = "";
			}
			delete params.apijwtuser_code;
		}

		//callback({"status":"Error", "message":"Debug"}); return;

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			callback(response);
		});

	}

	function customEditFieldInfo(inputlist, idx)
	{
		const fieldlist = ["apijwtuser_code"];
		if (!fieldlist.includes(inputlist[idx].field)) {
			return inputlist[idx];
		}

		var apijwtuser_providerusername = "";
		var curvalue = 0;
		var tmpidx = 0;

		while (tmpidx < inputlist.length) {
			if (inputlist[tmpidx].field === "apijwtuser_providerusername") {
				apijwtuser_providerusername = inputlist[tmpidx].value;
			}
			tmpidx++;
		}

		if (inputlist[idx].field === "apijwtuser_code") {
			var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (apijwtuser_providerusername === "") {
				tmpfield.mode = "readonly";
				tmpfield.input = "textbox";
				tmpfield.value =  getApijwtuserProviderusernameDisplay(apijwtuser_providerusername);
				return tmpfield;
			}
			curvalue =  getApijwtuserProviderusernameDisplay(apijwtuser_providerusername) === "Active"?1:0;
			if (apijwtuser_providerusername === "") {
				tmpfield.mode = "readonly";
			}
			tmpfield.value = curvalue;
			return tmpfield;
		}
		return inputlist[idx];
	} // customEditFieldInfo

	function customSearchField(field, idx, rowdata, styles)
	{
		if (field.dbfield === "apijwtuser_providerusername") {
			return <>{getApijwtuserProviderusernameDisplay(rowdata.apijwtuser_providerusername)}</>
		}
		return <></>
	}

	function customUserHandler(newtoken, userdata)
	{
		setUserfullname(userdata.name);
		setToken(newtoken);
	}

	function getApijwtuserProviderusernameDisplay(curvalue)
	{
		var display = "Active";
		if (curvalue === null) {
			display = "Initializing...";
		} else if (curvalue === "") {
			display = "Initializing...";
		} else if (curvalue.toLowerCase().indexOf("disable") >= 0) {
			display = "Disabled";
		}
		return display;
	}

	return <Layout fullPath={location.pathname}
			newUserHandler={customUserHandler}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={allowdelete}
				pagetitle={pagetitle}
				mobilerowtitlefield={["apijwtuser_name", "apijwtuser_identifier"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(customeditformfields)]}
				newformFields={[newFormFields[0].concat(customnewformfields)]}

				userSearchFilter={[{"field":"apijwtuser_name", "operation":" not like ", "value": userfullname}]}

				customSearchField={customSearchField}
				customEditFieldInfo={customEditFieldInfo}
				customSubmit={customSubmit}

				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default ApijwtuserForm;
